import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout/index.js";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import Wide from "../components-styled/Wide";
import { Flex, Box } from "theme-ui";
import PictureSet from "../components/PictureSet";
import imgSet08 from "../imgsets/imgset08";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Services" keywords={[]} mdxType="SEO" />
    <Wide mdxType="Wide">
      <PictureSet imgSet={imgSet08} mdxType="PictureSet" />
    </Wide>
    <h1>{`Services`}</h1>
    <h2>{`Erstberatung`}</h2>
    <p>{`Wenn ihr eine Erstberatung zu eurer Startup-Idee von einem der Gründungsservices an den Hochschulen haben möchtet, füllt bitte den `}<a parentName="p" {...{
        "href": "/downloads/hgnc_Fragebogen-Erstberatung_v1.0.0.pdf"
      }}>{`Fragebogen`}</a>{` und den `}<a parentName="p" {...{
        "href": "/downloads/hgnc_EXIST-Canvas_v1.0.0.pdf"
      }}>{`EXIST Canvas`}</a>{` aus.`}</p>
    <h2>{`Gründungsservices der Hochschulen`}</h2>
    <p>{`An den Kölner Hochschulen findet ihr Gründungsservices:`}</p>
    <p>{`CBS International Business School`}<br />{`
`}<a parentName="p" {...{
        "href": "https://cbs.de/hochschule/team/flora-carlhoff/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Flora Carlhoff M.A.`}</a><br />{`
Telefon: +49 221 931809-843`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:f.carlhoff@cbs.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`f.carlhoff@cbs.de`}</a><br /></p>
    <p>{`Deutsche Sporthochschule Köln`}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.dshs-koeln.de/visitenkarte/person/dr-marita-mehlstaeubl/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Dr. Marita Mehlstäubl`}</a><br />{`
Telefon: +49 221 4982-4605`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:m.mehlstaeubl@dshs-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`m.mehlstaeubl@dshs-koeln.de`}</a><br /></p>
    <p><a parentName="p" {...{
        "href": "https://www.gateway.uni-koeln.de/gruendungsberatung/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`GATEWAY / Universität zu Köln`}</a><br />{`
Frederik Lindner`}<br />{`
Telefon: +49 151 640 512 21`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:f.lindner@uni-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`f.lindner@uni-koeln.de`}</a><br />{`
Evelyn Berresheim-Christ`}<br />{`
Telefon: +49 151 640 512 20`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:e.berresheim-christ@uni-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`e.berresheim-christ@uni-koeln.de`}</a><br />{`
Sandra Schwarz`}<br />{`
Telefon: +49 175 436 7316`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:s.schwarz@uni-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`s.schwarz@uni-koeln.de`}</a><br /></p>
    <p>{`Rheinische Fachhochschule`}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.rfh-koeln.de/studium/startuprfh/index_ger.html",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Alina Ley M.A.`}</a><br />{`
Telefon: +49 221 20302-8020`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:alina.ley@rfh-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`alina.ley@rfh-koeln.de`}</a><br /></p>
    <p>{`Technische Hochschule Köln`}<br />{`
`}<a parentName="p" {...{
        "href": "https://www.th-koeln.de/personen/stephanie.grubenbecher/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Dr. Stephanie Grubenbecher`}</a><br />{`
`}<a parentName="p" {...{
        "href": "https://www.th-koeln.de/personen/catherine.miebach/",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Catherine Miebach`}</a><br />{`
Telefon: +49 221-8275-3086 /-5261`}<br />{`
E-Mail: `}<a parentName="p" {...{
        "href": "mailto:gruendungsservice@th-koeln.de",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`gruendungsservice@th-koeln.de`}</a><br /></p>
    <h2>{`Weitere Gründungsberatungen`}</h2>
    <p>{`TODO`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.ihk-koeln.de/Startup_Unit_der_IHK_Koeln.AxCMS",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Startup-Unit`}</a>{` – ein Beratungsangebot der IHK Köln`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      